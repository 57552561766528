import axios from "axios";
import oauth from "axios-oauth-client";
import param from "jquery-param";
import { toast } from "react-toastify";
import { css } from "emotion";
import Qs from "qs";

const baseUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_LEMOULIN_API_KEY;
const clientId = process.env.REACT_APP_LEMOULIN_API_CLIENT_ID;
const clientSecret = process.env.REACT_APP_LEMOULIN_API_CLIENT_SECRET;

const launchSuccessNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.SUCCESS,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)",
    }),
  });
};

const launchFailNotif = (notif, msg) => {
  toast.update(notif, {
    render: msg,
    type: toast.TYPE.ERROR,
    className: css({
      transition: "transform 0.6s",
      transform: "rotateY(360deg)",
    }),
  });
};

function handleErrors(err, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.error = err;

  next({
    type: `${action.type}_FAILED`,
    payload: payload,
    meta: action.meta,
  });
  return err;
}

function handleResponse(res, action, next) {
  let payload = {};
  if (action.payload) {
    payload = action.payload;
  }
  payload.result = res;

  next({
    type: `${action.type}_COMPLETED`,
    payload: payload,
    meta: action.meta,
  });
  return res;
}

function requestHeaders() {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // if(process.env.NODE_ENV !== 'production'){
  //   const username = 'romain';
  //   const password = 'L3oln';
  //   const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
  //   headers.Authorization = `Basic ${token}`;
  // }

  return headers;
}

const apiService = () => (next) => (action) => {
  const result = next(action);
  if (!action.meta || !action.meta.async) {
    return result;
  }

  // Juste pour l'authentification
  if (action.meta.auth) {
    const url = `${process.env.REACT_APP_API_URL}/oauth/v2/token`;
    const body = action.meta.body;
    const getOwnerCredentials = oauth.client(axios.create(), {
      url: url,
      grant_type: "password",
      client_id: clientId,
      client_secret: clientSecret,
      username: body.username,
      password: body.password,
    });
    const credentials = getOwnerCredentials();
    credentials.then(
      (res) => {
        window.localStorage.setItem("token", res.access_token);
        handleResponse(
          {
            data: {
              result: res.access_token,
            },
          },
          action,
          next
        );
      },
      (err) => {
        handleErrors(err, action, next);
      }
    );
  } else {
    let { path, method, body, params } = action.meta;

    if (!path) {
      throw new Error(`'path' not specified for async action ${action.type}`);
    }

    let url = `${baseUrl}${path}`;

    if (method === "GET") {
      body = params;
    }

    return axios({
      method: method,
      url: method === "GET" && body !== undefined ? `${url}${param(body)}` : url,
      headers: requestHeaders(),
      data: method !== "GET" ? JSON.stringify(body) : null,
    })
    .then(
      (res) => {
        if (!res || (res.success !== undefined && !res.success)) {
          return handleErrors(res, action, next);
        } else {
          return handleResponse(res, action, next);
        }
      },
      (err) => {
        return handleErrors(err, action, next);
      }
    );
  }
};

let notif = null;

// Gere la notif et le access token pour LeMoulinApi & ce apiService
axios.interceptors.request.use(
  function (config) {
    notif = toast.info("Chargement ...");

    const url = config.url;
    if (url.includes("?")) {
      config.url = config.url + `&apiKey=${apiKey}`;
    } else {
      config.url = config.url + `?apiKey=${apiKey}`;
    }

    config.paramsSerializer = (params) => {
      // Qs is already included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    let token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      window.localStorage.removeItem("token");
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Gere les erreurs et la notif
axios.interceptors.response.use(
  function (response) {
    let msg = "Mise à jour réussi";
    if (response.data && response.data.success === false) {
      msg = response.data.message
        ? response.data.message
        : "Une erreur est survenue";
      launchFailNotif(notif, msg);
      return response.data;
    }
    if (response.data && response.data.success && response.data.message) {
      msg = response.data.message;
    }
    launchSuccessNotif(notif, msg);
    return response.data;
  },
  function (error) {
    let errorMsg = "Une erreur est survenue";
    if (error.response && error.response.data && error.response.data.message) {
      errorMsg = error.response.data.message;
    }
    launchFailNotif(notif, errorMsg);

    if (error.response) {
      // Erreur d'authentification
      if (error.response && error.response.status === 401) {
        window.localStorage.removeItem("token");
        window.location.reload();
        return;
      }
      // Mauvaise requete
      if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        let data = error.response.data;
        data.error = true;
        return data;
      }
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);

    return Promise.reject(error);
  }
);

export default apiService;
