import * as Yup from "yup";
import moment from 'moment';

Yup.setLocale({
  mixed: {
    required: '${path} est obligatoire',
    notType: '${path} est manquant ou invalide dans son format'
  },
  string: {
    email: '${path} doit être un email valide',
  },
  date: {
    required: '${path} doit être une date',
  },
});

Yup.addMethod(Yup.string, 'dateString', function () {
  return this.test('is-date', 'Format de date invalide', value => {
    const isValid = moment(value, 'DD/MM/YYYY', true).isValid();
    return isValid ? true : new Error('Format de date invalide');
  });
});

const BreakfastOrderApiSchema = Yup.object().shape({
  customer: Yup.string().label('Client').required(),
  company: Yup.string().label('Entreprise').required(),
  format: Yup.string().label('Format').required(),
  delivery_date: Yup.string()
    .required()
    .label('Date de livraison')
    .dateString(),
  confirmTender: Yup.string().label('Confirmer AO').required(),
  delivery: Yup.object()
    .shape({
      firstname: Yup.string().label('Prénom (livraison)').min(2, 'Le prénom (livraison) doit comporter au moins 2 caractères').required(),
      lastname: Yup.string().label('Nom (livraison)').min(2, 'Le nom (livraison) doit comporter au moins 2 caractères').required(),
      phone: Yup.string().label('Téléphone (livraison)').matches(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$|^$/, 'Le numéro de téléphone (livraison) n\'est pas valide').required(),
      street_number: Yup.string().label('Numéro de rue (livraison)').notRequired().nullable(),
      street: Yup.string().label('Rue (livraison)').min(2, 'La rue (livraison) doit comporter au moins 2 caractères').notRequired().nullable(),
      postal_code: Yup.string().label('Code postal (livraison)').matches(/^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$|^$/, 'Le code postal (livraison) n\'est pas valide').notRequired().nullable(),
      city: Yup.string().label('Ville (livraison)').notRequired().nullable(),
    })
    .required(),
  status: Yup.string().label('Statut').required(),
  deliveryAddress: Yup.string().label('Adresse de livraison').required(),
  billingAddress: Yup.string().label('Adresse de facturation').required(),
  firstname: Yup.string().label('Prénom (facturation)').min(2, 'Le prénom (facturation) doit comporter au moins 2 caractères').notRequired().nullable(),
  lastname: Yup.string().label('Nom (facturation)').min(2, 'Le nom (facturation) doit comporter au moins 2 caractères').notRequired().nullable(),
  billingCompany: Yup.string().label('Entreprise de facturation').notRequired().nullable(),
  phone: Yup.string().label('Téléphone').matches(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$|^$/, 'Le numéro de téléphone (facturation) n\'est pas valide').required(),
  address: Yup.string().label('Adresse (facturation)').required(),
  street_number: Yup.string().label('Numéro de rue (facturation)').required(),
  street: Yup.string().label('Rue (facturation)').min(2, 'La rue (facturation) doit comporter au moins 2 caractères').required(),
  postal_code: Yup.string().label('Code postal (facturation)').matches(/^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$|^$/, 'Le code postal (facturation) n\'est pas valide').required(),
  city: Yup.string().label('Ville (facturation)').required(),
  participants_number: Yup.string().label('Nombre de participants').required(),
  formulas: Yup.array().label('Formules'),
  products: Yup.array().label('Produits'),
  }).test(
    'formulasOrProducts',
    "Merci d'ajouter au moins un produit ou une formule à la commande",
    (obj) => obj.formulas.length > 0 || obj.products.length > 0
  );

export default BreakfastOrderApiSchema;
