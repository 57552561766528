/**
 * Affiche un formulaire d'authentification si aucun token est présent dans le cache
 */
import React, { createContext, useContext, useState } from 'react'
import LeMoulinApi from 'app/universal/utils/LeMoulinApi'
import Login from 'app/login/pages/Index'

const ApiAuthContext = createContext(null)
const ApiAuthProvider = (props) => {

  const [accessToken, setAccessToken] = useState(() => {
    if (window.localStorage.getItem('token') === null) {
      return ''
    }
    return window.localStorage.getItem('token')
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token  = await LeMoulinApi.getToken(event.target.username.value, event.target.password.value)
    window.localStorage.setItem('token', token)
    setAccessToken(token)
  }
  
  // On regarde si on detecte le param public dans l'url
  const queryString = require('query-string')
  const query = queryString.parse(window.location.search);

  // Pas d'authentification dans le cas de certaines url impression planning avec le param publicUrl
  let isPublicUrl = window.location.pathname == "/impression-planning-public" ? true : false;

  return (
      isPublicUrl == true
    ?
      <>{props.children}</>
    : 
      accessToken === ''
    ?
      <Login handleSubmit={handleSubmit} />
    :
      <ApiAuthContext.Provider value={accessToken}>
        {props.children}
      </ApiAuthContext.Provider>
  )

}

export default ApiAuthProvider
export const useToken = () => useContext(ApiAuthContext)
